import { Show } from 'solid-js';
import type { JSX } from 'solid-js';

import Pro from 'icons/Pro.svg';

export default function ProBadge(props: {
  when?: boolean;
  class?: string;
  fallback?: JSX.Element;
}) {
  return <Show when={props.when !== false}
    fallback={props.fallback ?? <>{/* The fragment is here to not let solid break the render when this component is the only child */}</>}
  >
    <span class="=pro-badge app-text-footnote c-tg_hint inline-flex items-center gap-1"
      classList={{ [String(props.class)]: !!props.class }}
    >
      <Pro class="= h-4 w-4" />
      Pro
    </span>
  </Show>;
}